<template>
  <transition name="modal">
    <div
      v-if="showModal"
      class="modal-mask fixed z-[9998] top-0 left-0 w-full h-full bg-[rgba(0,32,63,0.7)] table transition-opacity duration-300 ease-in-out"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
    >
      <div
        :id="wrapperId"
        class="modal-wrapper absolute flex max-h-[90%] w-full max-w-[600px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-[calc(50%+1px)]"
        :class="{
          '!max-w-max': maxContent,
          '!max-w-[500px]': md,
          '!max-w-[900px]': lg,
          '!max-w-[1250px]': xl,
          '!max-w-[1500px]': xxl
        }"
      >
        <LocalLoader isMedium :loading="loading" />
        <div
          class="modal-container relative w-fit-content w-full mx-auto overflow-y-auto bg-white rounded-lg shadow-md transition-all duration-300 ease py-6"
          :class="{
            'blur-[1.5px]': loading,
            '!overflow-visible': overflowVisible
          }"
          @click="handleClick"
        >
          <div
            class="modal-header relative"
            :class="{
              'border-b border-neutral-80 pb-6': headerBorder
            }"
          >
            <i
              v-if="close"
              class="fas fa-times absolute right-8 top-0.5 cursor-pointer text-neutral-100 hover:text-neutral-200"
              @click="(e) => emit('close', e)"
            />
            <div class="px-4 mobileLandscape:px-8">
              <slot name="header" />
            </div>
          </div>
          <div class="modal-body my-4 px-4 mobileLandscape:px-8">
            <slot name="body" />
          </div>
          <div
            class="modal-footer"
            :class="{ 'border-t border-neutral-80': footerBorder }"
          >
            <div class="px-4 mobileLandscape:px-8">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import { ref } from 'vue'

const emit = defineEmits(['close'])
const props = defineProps({
  showModal: Boolean,
  close: Boolean,
  loading: Boolean,
  maxContent: Boolean,
  md: Boolean,
  lg: Boolean,
  xl: Boolean,
  xxl: Boolean,
  overflowVisible: Boolean,
  headerBorder: {
    type: Boolean,
    default: false
  },
  footerBorder: {
    type: Boolean,
    default: false
  },
  wrapperId: {
    type: String,
    default: ''
  }
})

const mouseDownInside = ref(false)

function handleMouseDown(e) {
  mouseDownInside.value = e.target.closest('.modal-container') !== null
}

function handleMouseUp(e) {
  const mouseUpInside = e.target.closest('.modal-container') !== null
  if (!mouseDownInside.value && !mouseUpInside) {
    emit('close', e)
  }
}

function handleClick(e) {
  if (e.target.type !== 'file') {
    e.preventDefault()
  }
}
</script>
