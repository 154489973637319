<template>
  <div
    ref="buttonNew"
    class="buttonNew absolute right-3 h-fit w-fit cursor-pointer opacity-100 z-[1] transition-opacity duration-[100ms] ease-in-out"
  >
    <div class="relative">
      <Tooltip
        black-theme
        :position="tooltipPosition"
        :disabled="showPopup"
        :description="$t('shared.label.more_options')"
        class="[&>.tooltipTarget]:flex"
      >
        <font-awesome-icon
          v-click-outside="onClickOutside"
          class="py-1.5 px-3 inline-block text-neutral-200 bg-white border border-neutral-80 rounded hover:text-black"
          :class="{
            '!border-none !bg-transparent': iconOnly,
            'hover:!bg-neutral-80': grayHover
          }"
          icon="fa-ellipsis-vertical"
          @click.prevent.stop="onOpen($event)"
        />
        <div
          v-if="showPopup"
          ref="popupRef"
          class="popup absolute hidden right-0 bg-white min-w-[150px] max-w-[300px] translate-y-0 shadow-sm border border-neutral-80 p-2 rounded"
          :class="{
            'rounded-lg': rounded,
            'top-0': !popupTop && !popupAbove,
            'bottom-0': popupTop || popupAbove,
            'w-[200px]': size === 'md',
            'w-max': size === 'max'
          }"
        >
          <div v-if="title" class="flex items-center justify-between mb-1">
            <span class="pl-1 uppercase text-black text-xs block">{{
              $t('shared.label.actions')
            }}</span>
            <font-awesome-icon
              class="icon text-neutral-200 hover:text-black"
              data-cy="close_edit"
              icon="fa-close"
              @click.prevent.stop="onClose($event)"
            />
          </div>
          <div
            class="flex flex-col text-sm [&>*]:px-1 [&>*]:py-0.5 [&>*]:text-neutral-200 [&>*]:rounded [&>*:hover]:bg-neutral-50"
            @click="onClose($event)"
          >
            <slot name="option" />
          </div>
        </div>
      </Tooltip>
    </div>
  </div>
</template>
<script setup>
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'

const $t = i18n.t

const props = defineProps({
  iconOnly: Boolean,
  grayHover: Boolean,
  rounded: Boolean,
  title: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'default'
  },
  popupTop: {
    type: Boolean,
    default: false
  },
  tooltipPosition: {
    type: String,
    default: 'left'
  }
})

const showPopup = ref(false)
const buttonNew = ref(null)
const popupRef = ref(null)
const popupAbove = ref(false)

defineExpose({
  showPopup
})

onMounted(() => {
  window.addEventListener('resize', adjustDropdownPosition)
  window.addEventListener('scroll', adjustDropdownPosition, true)
})

onUnmounted(() => {
  window.removeEventListener('resize', adjustDropdownPosition)
  window.removeEventListener('scroll', adjustDropdownPosition, true)
})

function onOpen(e) {
  showPopup.value = true
  nextTick(() => {
    let activeBtn = document.querySelector('.buttonNew--active')
    if (activeBtn) {
      activeBtn.classList.remove('buttonNew--active')
      activeBtn.classList.remove('!z-[2]')
    }
    let popups = [...document.querySelectorAll('.popup--active')]
    if (popups.length > 0) {
      popups[0].classList.remove('popup--active')
      popups[0].classList.remove('!block')
    }
    let children = [...e.target.parentElement.children]
    children.forEach((child) => {
      let childExists = child.classList.contains('popup')
      if (childExists) {
        child.classList.add('popup--active')
        child.classList.add('!block')
        buttonNew.value.classList.add('buttonNew--active')
        buttonNew.value.classList.add('!z-[2]')
      }
    })
    adjustDropdownPosition()
  })
}
function onClose(e) {
  showPopup.value = false
  nextTick(() => {
    const btn = buttonNew.value
    if (btn) {
      btn.classList.remove('buttonNew--active')
      btn.classList.remove('!z-[2]')
    }
    if (document.querySelector('.popup')) {
      document.querySelector('.popup').classList.remove('!block')
    }
    let exists = e.target.closest('.popup').classList.contains('popup--active')
    if (exists) {
      e.target.closest('.popup').classList.remove('!block')
    }
  })
}
function onClickOutside() {
  showPopup.value = false
}

function adjustDropdownPosition() {
  if (!buttonNew.value || !popupRef.value || props.popupTop) return
  nextTick(() => {
    const parentRect = buttonNew.value.getBoundingClientRect()
    const popupHeight = popupRef.value.offsetHeight
    const windowHeight = window.innerHeight

    popupAbove.value = parentRect.bottom + popupHeight + 10 > windowHeight
  })
}
</script>
